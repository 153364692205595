import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {
    loadRoamingVerificationActsAsync, loadRoamingVerificationActsCountAsync,
    selectCount,
    selectRoamingVerificationActs,
    selectVerificationActFilterOptions
} from "../../store/reducers/roaming/vertification-act/vertificationActReducer";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import dayjs from "dayjs";
import {VIEW_DRAFT, VIEW_INBOX} from "../../enum/roaming/TypeViewers";
import Badge from "../../components/common/Badge";
import IconFolderDownload from "../../assets/icon/IconFolderDownload";
import numeral from "numeral";
import {VerificationActWrapper} from "../../enum/roaming/vertification-act/VerificationActWrapper";
import IconTransaction from "../../assets/icon/IconTransaction";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import DashboardDatatable from "./DashboardDatatable";

const DashboardVerificationAct = ({setVerificationAct}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const roamingVerificationActs = useSelector(selectRoamingVerificationActs);
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const t = Translate;
    const activeOrganizationPinflOrInn = activeOrganization?.pinfl || activeOrganization?.inn
    const [viewer, setViewer] = useState("inbox")

    useEffect(() => {
        let params = {page: 1, limit: 3, type_viewer: viewer, status: 15}
        dispatch(loadRoamingVerificationActsAsync(params));
        dispatch(loadRoamingVerificationActsCountAsync({...params}))
    }, [activeOrganization, viewer]);

    useEffect(() => {
        setVerificationAct(count)
    }, [count]);

    const columns = [
        {
            accessor: 'contractor',
            Header: t(lang, "edi.common.label.organization"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: "ps-3"},
            Cell: ({row: {original}}) => {
                return (
                    <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span
                                 className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.executor.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.executor.inn || original.executor.pinfl}</i>
                        </div>
                    </div>

                )
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.verification_act.datatable.header.row.total_sum"),
            cellProps: {
                className: 'text-end'
            },
            headerProps: {width: 300, className: 'ps-3 text-end'},
            Cell: ({row: {original}}) => {
                if (original?.executor?.inn === activeOrganizationPinflOrInn) {
                    return (
                        <strong>
                            {original?.close_balance?.executor_debit
                                ? `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")} ${numeral.formats[numberFormat].format((original?.close_balance?.executor_debit || 0))}`
                                : `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")} ${numeral.formats[numberFormat].format((original?.close_balance?.executor_credit || 0))}`
                            }
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </strong>
                    )
                } else {
                    return (
                        <strong>
                            {original?.close_balance?.customer_debit
                                ? `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")} ${numeral.formats[numberFormat].format((original?.close_balance?.customer_debit || 0))}`
                                : `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")} ${numeral.formats[numberFormat].format((original?.close_balance?.customer_credit || 0))}`
                            }
                            <span className={'ms-1 second-text fs-9'}>UZS</span>
                        </strong>
                    )
                }
            }
        },
        (VIEW_DRAFT !== viewer && {
            accessor: 'status',
            Header: t(lang, "roaming.verification_act.datatable.header.row.status"),
            headerProps: {className: 'text-end ps-3'},
            cellProps: {className: 'text-end ps-3'},
            Cell: ({row: {original}}) => {
                const verificationActWrapper = new VerificationActWrapper(original)

                return <div className={'d-inline-block text-center'}>
                    <Badge
                        bg={verificationActWrapper.getRoamingStatusVariant(activeOrganization.inn, activeOrganization.pinfl)}
                        variant={"phoenix"}
                        className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                    >
                        {t(lang, verificationActWrapper.getRoamingStatus(activeOrganization.inn, activeOrganization.pinfl))}
                    </Badge>
                </div>
            }
        }),
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    if (viewer === VIEW_DRAFT) {
                                        navigate('/roaming/verification-act/draft/' + original.id)
                                    } else {
                                        navigate('/roaming/verification-act/' + original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },

    ].filter(i => i);


    return (
        <DashboardDatatable
            columns={columns}
            data={roamingVerificationActs}
            setViewer={setViewer}
            documentName={'edi.navbar.routes.edo.verification_act'}
        />
    );
};

export default DashboardVerificationAct;
