import React, {Fragment, useEffect, useState} from 'react';
import AdvanceTableWrapper from "../../components/table/AdvanceTableWrapper";
import AdvanceTable from "../../components/table/AdvanceTable";
import {Card, Dropdown} from "react-bootstrap";
import dayjs from "dayjs";
import Badge from "../../components/common/Badge";
import IconFolderDownload from "../../assets/icon/IconFolderDownload";
import numeral from "numeral";
import {
    DRAFT,
    RoamingInvoiceWrapper,
    StatusDeclinedByCustomerOrPerson
} from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import ShowNoteModal from "../../components/roaming/invoice/ShowNoteModal";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {loadSettings} from "../../store/reducers/settings/settingsReducer";
import {
    loadRoamingInvoicesAsync,
    loadRoamingInvoicesCountAsync, selectCount, selectRoamingInvoices
} from "../../store/reducers/roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import IconTransaction from "../../assets/icon/IconTransaction";
import Flex from "../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faEye} from "@fortawesome/free-solid-svg-icons";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {useNavigate} from "react-router-dom";
import {GetViewerText, VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../enum/roaming/TypeViewers";
import DashboardDatatable from "./DashboardDatatable";

const DashboardInvoiceDatatable = ({setInvoiceCount}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roamingInvoices = useSelector(selectRoamingInvoices);
    const count = useSelector(selectCount)
    const activeOrganization = useSelector(selectActiveOrganization);
    const numberFormat = useSelector(selectNumberFormat);

    const [viewer, setViewer] = useState("inbox")

    useEffect(() => {
        setInvoiceCount(count)
    }, [count]);

    useEffect(() => {
        loadInvoices()
    }, [viewer]);

    // load invoices
    const loadInvoices = () => {
        dispatch(loadRoamingInvoicesAsync(viewer, {page: 1, limit: 3, status: 15}));
        dispatch(loadRoamingInvoicesCountAsync(viewer, {page: 1, limit: 3, status: 15}));
    }
    const columns = [
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.invoices.datatable.header.row.contractor"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: 'ps-3'},
            Cell: ({row: {original}}) => {
                if (original.single_sided_type === 0) {
                    if (activeOrganization.inn === original.executor.inn || activeOrganization.pinfl === original.executor.inn)
                        return <div className={'d-flex align-items-center gap-2 text-truncate'}>
                            <IconTransaction/>
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                    "contract_number": original?.info?.number,
                                    "contract_date": original?.info?.date
                                })}
                                </span>
                                <div style={{
                                    maxWidth: '300px',
                                    minWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }} title={original.customer.name}
                                     className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</div>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn}</i>
                            </div>
                        </div>
                    else
                        return <div className={'d-flex align-items-center gap-2 text-truncate'}>
                            <IconTransaction/>
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                    "contract_number": original?.info?.number,
                                    "contract_date": original?.info?.date
                                })}
                                </span>
                                <div style={{
                                    maxWidth: '300px',
                                    minWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }} title={original.executor.name}
                                     className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</div>
                                <i className={'d-block fs-9 second-text'}>{original.executor.inn}</i>
                            </div>
                        </div>
                }
                if (original?.content) {
                    if (activeOrganization?.inn === original?.executor?.inn || activeOrganization?.pinfl === original?.executor?.inn)
                        return <div className={'d-flex align-items-center gap-2 text-truncate'}>
                            <IconTransaction/>
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                    "contract_number": original?.info?.number,
                                    "contract_date": original?.info?.date
                                })}
                            </span>
                                <div style={{
                                    maxWidth: '300px',
                                    minWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                     title={original.customer.name}
                                     className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</div>
                                <i className={'d-block fs-9 second-text'}>{original.customer.inn}</i>
                            </div>
                        </div>
                    else
                        return <div className={'d-flex align-items-center gap-2 text-truncate'}>
                            <IconTransaction/>
                            <div>
                                <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, 'roaming.empowerment.contact_info.from.contact_date', {
                                    "contract_number": original?.info?.number,
                                    "contract_date": original?.info?.date
                                })}
                            </span>
                                <div style={{
                                    maxWidth: '300px',
                                    minWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                     title={original.executor.name}
                                     className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</div>
                                <i className={'d-block fs-9 second-text'}>{original.executor.inn}</i>
                            </div>
                        </div>
                }
                return null;
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.invoices.datatable.header.row.total_sum"),
            cellProps: {className: 'pe-3 text-end'},
            headerProps: {width: 300, className: 'ps-3 text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <span className={'d-block text-end fw-semi-bold'}>
                        {/*<strong>{numeral((original.total_info.total_with_nds)).format(numberFormat)}</strong>*/}
                        <strong>{numeral['formats'][numberFormat].format((original.total_info.total_with_nds))}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </span>
                )
            }
        },
        {
            accessor: 'status',
            Header: () => t(lang, "roaming.invoices.datatable.header.row.status"),
            headerProps: {className: 'text-end pe-3'},
            cellProps: {className: 'text-end pe-3'},
            Cell: ({row: {original}}) => {
                const invoiceWrapper = new RoamingInvoiceWrapper(original)
                const showDeclineModal = StatusDeclinedByCustomerOrPerson === original.status
                if (showDeclineModal) {
                    return (
                        <ShowNoteModal
                            notes={original.notes}
                            bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                            title={t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                        />
                    )
                } else {
                    return (
                        <div className={'d-inline-block text-center'}>
                            <Badge
                                className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                                bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn) !== 'dark' ?
                                    invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn) : 'secondary'}
                                variant={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn) !== 'dark' ?
                                    'phoenix' : null}
                            >
                                {t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                            </Badge>
                        </div>
                    )
                }
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    if (viewer === DRAFT) {
                                        navigate('/roaming/invoice/draft/' + original.id)
                                    } else {
                                        navigate('/roaming/invoice/' + original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ];

    return (
        <DashboardDatatable
            columns={columns}
            data={roamingInvoices}
            setViewer={setViewer}
            documentName={'edi.navbar.routes.edo.invoice'}
        />
    );
};

export default DashboardInvoiceDatatable;
