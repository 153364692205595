import React from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Button, Modal} from "react-bootstrap";
import IconAlertPrimary from "../../assets/icon/IconAlertPrimary";

const RedirectAlert = ({show, setShow}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const handleRedirect = () => {
        window.open('https://davrwms.hippo.uz/', '_blank');
        setShow(false);
    }

    return (
        <Modal centered show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton />
            <Modal.Body className={'text-center'}>
                <IconAlertPrimary width={100} height={100} />
                <h3 className={'text-center fw-medium'}>{t(lang, "main.redirect.title")}</h3>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(false)} variant={'phoenix-secondary'}>{t(lang, "items.common.close")}</Button>
                <Button onClick={handleRedirect} variant={'phoenix-primary'}>{t(lang, "Переход")}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RedirectAlert;
