import {UilInvoice} from "@iconscout/react-unicons";

export const BILLING = "billing"
export const PACKAGES = "packages"
export const CURRENCY = "currency"
export const NOTIFICATION = "notification"
export const ORGANIZATION = "organization"

export const settingsServices = [
   /* {
        title: 'main.top.nav.profile_dropdown.dropdown_item.billing',
        to: '/user/billing',
        query: 'billing',
        pathName: 'default-dashboard',
        topNavIcon: 'file-plus',
        active: true
    },
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.packages',
        to: '/user/packages',
        query: 'packages',
        pathName: 'default-dashboard',
        topNavIcon: 'file-plus',
        active: true
    },
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.currency',
        to: '/user/currency',
        query: 'currency',
        pathName: 'default-dashboard',
        topNavIcon: 'dollar-sign',
        active: true
    },
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.notification',
        to: '/user/notification',
        query: 'notification',
        pathName: 'default-dashboard',
        topNavIcon: 'bell',
        active: true
    },
    {
        title: 'hippo.user.settings.profile.title',
        to: '/user/info-organization',
        query: 'organization',
        pathName: 'default-dashboard',
        topNavIcon: 'info',
        active: true
    },*/
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.system',
        to: '/user/systems',
        query: 'systems',
        pathName: 'default-dashboard',
        topNavIcon: 'sliders',
        active: true
    },
    {
        title: 'main.settings.tab.templates.title',
        to: '/user/sample',
        query: 'sample',
        pathName: 'default-dashboard',
        topNavIcon: 'trello',
        active: true
    },
]

export const settingsQuery = (query) => {
    switch (query[0]) {
        case BILLING :
            return "main.top.nav.profile_dropdown.dropdown_item.billing"
        case PACKAGES :
            return "main.top.nav.profile_dropdown.dropdown_item.packages"
        case CURRENCY :
            return "main.common.currency"
        case NOTIFICATION :
            return "notification.name"
        case ORGANIZATION :
            return "edi.common.user_organization"
        default :
            return "main.billing.info.title"
    }
}
