export const AttorneyFieldOptions = [
    {key: 'attorney_number', label: "edi.attorney.mass_import.column.attorney_number", isRequired: true},
    {key: 'attorney_date_start', label: "edi.attorney.mass_import.column.attorney_date_start", isRequired: true},
    {key: 'attorney_date_end', label: "edi.attorney.mass_import.column.attorney_date_end", isRequired: true},

    {key: 'contract_number', label: "edi.attorney.mass_import.column.contract_number", isRequired: true},
    {key: 'contract_date', label: "edi.attorney.mass_import.column.contract_date", isRequired: true},

    {key: 'owner_inn', label: "edi.attorney.mass_import.column.owner_inn", isRequired: true},
    {key: 'owner_name', label: "edi.attorney.mass_import.column.owner_name", isRequired: false},
    // {key: 'owner_oked', label: "edi.attorney.mass_import.column.owner_oked", isRequired: false},
    // {key: 'owner_address', label: "edi.attorney.mass_import.column.owner_address", isRequired: false},

    {key: 'contractor_inn', label: "edi.attorney.mass_import.column.contractor_inn", isRequired: true},
    {key: 'contractor_name', label: "edi.attorney.mass_import.column.contractor_name", isRequired: false},
    // {key: 'contractor_oked', label: "edi.attorney.mass_import.column.contractor_oked", isRequired: false},
    // {key: 'contractor_address', label: "edi.attorney.mass_import.column.contractor_address", isRequired: false},

    {key: 'person_inn', label: "edi.attorney.mass_import.column.person_inn", isRequired: true},
    {key: 'person_full_name', label: "edi.attorney.mass_import.column.person_full_name", isRequired: true},
    {key: 'person_position', label: "edi.attorney.mass_import.column.person_position", isRequired: true},

    {key: 'passport_number', label: "edi.attorney.mass_import.column.passport_number", isRequired: false},
    {key: 'passport_serial', label: "edi.attorney.mass_import.column.passport_serial", isRequired: false},
    {key: 'passport_issuer', label: "edi.attorney.mass_import.column.passport_issuer", isRequired: false},
    {key: 'passport_issued_on', label: "edi.attorney.mass_import.column.passport_issued_on", isRequired: false},

    {key: 'accountant', label: "edi.attorney.mass_import.column.accountant", isRequired: false},
    {key: 'director', label: "edi.attorney.mass_import.column.director", isRequired: false},

    {key: 'product_name', label: 'edi.attorney.mass_import.column.product', isRequired: true},
    {key: 'product_measurement', label: 'edi.attorney.mass_import.column.measurement', isRequired: true},
    {key: 'product_quantity', label: 'edi.attorney.mass_import.column.quantity', isRequired: true},
    {key: 'product_quantity_in_words', label: 'edi.attorney.mass_import.column.quantity_in_words', isRequired: false},
];

export const UnimportantOption = {key: 'unimportant', label: "edi.attorney.mass_import.column.unimportant"};

export const MassAttorneyImportSelectorOptions = [...AttorneyFieldOptions, {...UnimportantOption}];

