import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import {selectDateDayJSFormat, selectLang} from "../../store/reducers/main/main";
import {
    loadFreeFormReceiverDocumentsAsync,
    loadFreeFormReceiverDocumentsCountAsync,
    loadFreeFormSenderDocumentsAsync,
    loadFreeFormSenderDocumentsCountAsync,
    selectFreeFormDocuments,
    selectFreeFormDocumentsCount,
    selectFreeFormDocumentsFilterOptions,
    selectFreeFormDocumentsLoading
} from "../../store/reducers/roaming/free-form/freeFormDocumentReducer";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import dayjs from "dayjs";
import Badge from "../../components/common/Badge";
import {AwaitSenderSign, GetStatusText, GetStatusVariant} from "../../enum/roaming/free-form/FreeFormDocumentStatus";
import IconTransaction from "../../assets/icon/IconTransaction";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import DashboardDatatable from "./DashboardDatatable";
import RoamingFreeForms from "../roaming/free-forms/RoamingFreeForms";

const DashboardFreeFormDatatable = ({setFreeFormCount}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);
    const count = useSelector(selectFreeFormDocumentsCount);
    const loading = useSelector(selectFreeFormDocumentsLoading);
    const freeFormDocuments = useSelector(selectFreeFormDocuments);
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const [viewer, setViewer] = useState("outbox")

    useEffect(() => {
        loadFreeFormDocuments();
    }, [filter, activeOrganization, viewer])

    const loadFreeFormDocuments = () => {
        switch (viewer) {
            case RoamingFreeForms.ViewTypeInbox:
                dispatch(loadFreeFormReceiverDocumentsAsync({page: 1, limit: 3, status: AwaitSenderSign}));
                dispatch(loadFreeFormReceiverDocumentsCountAsync({page: 1, limit: 3, status: AwaitSenderSign}));
                break;

            case RoamingFreeForms.ViewTypeOutbox:
                dispatch(loadFreeFormSenderDocumentsAsync({page: 1, limit: 3, status: AwaitSenderSign}));
                dispatch(loadFreeFormSenderDocumentsCountAsync({page: 1, limit: 3, status: AwaitSenderSign}));
                break;
        }
    }

    useEffect(() => {
        setFreeFormCount(count)
    }, [count]);

    const columns = [
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_counterparty'),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: 'ps-3'},
            Cell: ({row: {original}}) => {
                return activeOrganization.inn === original.sender.inn
                    ? <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.receiver.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.receiver.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.receiver.inn || original.receiver.pinfl}</i>
                        </div>
                    </div>
                    : <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.sender.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.sender.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.sender.inn || original.sender.pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'status',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_status'),
            cellProps: {
                className: 'text-end'
            },
            headerProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className={'d-inline-block text-center'}>
                        <Badge variant={'phoenix'} bg={'warning-davr'}
                               className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}>
                            {t(lang, GetStatusText(original.status))}
                        </Badge>
                    </div>
                )
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    navigate('/roaming/free-form/' + original.id)
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ];

    return (
        <DashboardDatatable
            columns={columns}
            data={freeFormDocuments}
            setViewer={setViewer}
            draft={true}
            documentName={'edi.navbar.routes.edo.free_form_document'}
        />
    );
};

export default DashboardFreeFormDatatable;
