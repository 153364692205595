export const AwaitSenderSign = 0;
export const SenderSent = 15;
export const SenderCancel = 17;
export const ReceiverDecline = 20;
export const ReceiverAccept = 30;

export const GetStatusText = status => {
    switch (status) {
        case AwaitSenderSign:
            return 'roaming.free_form_document.status.await_sender_sign';
        case SenderSent:
            return 'roaming.free_form_document.status.sender_sent';
        case SenderCancel:
            return 'roaming.free_form_document.status.sender_cancel';
        case ReceiverDecline:
            return 'roaming.free_form_document.status.receiver_decline';
        case ReceiverAccept:
            return 'roaming.free_form_document.status.receiver_accept';
    }
}

export const GetStatusVariant = status => {
    switch (status) {
        case AwaitSenderSign:
            return 'secondary';
        case SenderSent:
            return 'primary';
        case SenderCancel:
            return 'warning';
        case ReceiverDecline:
            return 'danger';
        case ReceiverAccept:
            return 'success';
    }
}

const FreeFormDocumentStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const FreeFormDocumentStatuses = [
    FreeFormDocumentStatus(AwaitSenderSign, GetStatusText(AwaitSenderSign)),
    FreeFormDocumentStatus(SenderSent, GetStatusText(SenderSent)),
    FreeFormDocumentStatus(SenderCancel, GetStatusText(SenderCancel)),
    FreeFormDocumentStatus(ReceiverDecline, GetStatusText(ReceiverDecline)),
    FreeFormDocumentStatus(ReceiverAccept, GetStatusText(ReceiverAccept)),
];


export const getStatusHistory = (status) => {
    switch (status) {
        case SenderSent:
            return "roaming.common.history_send"
        case SenderCancel:
            return "roaming.common.history_cancel"
        case ReceiverDecline:
            return "roaming.common.history_decline"
        case ReceiverAccept:
            return "roaming.common.history_accept"
        default:
            return "";
    }
}

export const getStatusShowExecutorHistory = (status) => {
    switch (status) {
        case SenderSent:
            return true
        case SenderCancel:
            return true
        default:
            return false;
    }
}

export const getStatusShowCustomerHistory = (status) => {
    switch (status) {
        case ReceiverDecline:
            return true
        case ReceiverAccept:
            return true
        default:
            return false;
    }
}

export const getStatusHistoryVariant = (status) => {
    switch (status) {
        case SenderSent:
            return "bg-100 text-secondary"
        case SenderCancel:
            return "border-danger text-danger"
        case ReceiverDecline:
            return "border-warning text-warning"
        case ReceiverAccept:
            return "border-success text-success"
        default:
            return "";
    }
}

export const getStatusHistoryBg = (status) => {
    switch (status) {
        case SenderSent:
            return ""
        case SenderCancel:
            return "rgba(237, 85, 101, 0.1)"
        case ReceiverDecline:
            return "rgba(248, 172, 89, 0.1)"
        case ReceiverAccept:
            return "rgba(26, 179, 148, 0.1)"
        default:
            return "";
    }
}
