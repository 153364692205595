import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {
    getCarrierWaybillsCount,
    getCustomerWaybillsCount, getWaybillDraftsCount, loadCarrierWaybillsAsync,
    loadCustomerWaybillsAsync, loadWaybillDraftsAsync,
    selectWaybills,
    selectWaybillsCount, selectWaybillsFilterOptions,
    selectWaybillsLoading
} from "../../store/reducers/roaming/waybill/roamingWaybillReducer";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import dayjs from "dayjs";
import Badge from "../../components/common/Badge";
import IconFolderDownload from "../../assets/icon/IconFolderDownload";
import numeral from "numeral";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../enum/roaming/TypeViewers";
import {getWaybillStatusText, getWaybillStatusVariant} from "../../enum/roaming/waybill/WaybillStatus";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import IconTransaction from "../../assets/icon/IconTransaction";
import DashboardDatatable from "./DashboardDatatable";

const DashboardWaybillDatatable = ({setWaybillCount}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);
    const waybills = useSelector(selectWaybills);
    const count = useSelector(selectWaybillsCount);
    const loading = useSelector(selectWaybillsLoading);
    const numberFormat = useSelector(selectNumberFormat);
    const filter = useSelector(selectWaybillsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const [viewer, setViewer] = useState("inbox")
    const viewer_inn_or_pinfl = activeOrganization?.pinfl || activeOrganization?.inn;

    useEffect(() => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadCustomerWaybillsAsync({page: 1, limit: 3, status: 15, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getCustomerWaybillsCount({viewer_inn: viewer_inn_or_pinfl}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadCarrierWaybillsAsync({page: 1, limit: 3, status: 15, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getCarrierWaybillsCount({viewer_inn: viewer_inn_or_pinfl}));
        } else if (viewer === VIEW_DRAFT) {
            dispatch(loadWaybillDraftsAsync({page: 1, limit: 3, status: 15, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getWaybillDraftsCount({viewer_inn: viewer_inn_or_pinfl}));
        }
    }, [activeOrganization, viewer])

    useEffect(() => {
        setWaybillCount(count)
    }, [count]);

    const columns = [
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.waybill.datatable.row.contractor'),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: "ps-3"},
            Cell: ({row: {original}}) => {
                return viewer_inn_or_pinfl === original.customer.inn
                    ?
                    <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.carrier.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.carrier.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.carrier.inn || original.carrier.pinfl}</i>
                        </div>
                    </div>
                    : <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.customer.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.customer.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.customer.inn || original.customer.pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'total',
            Header: t(lang, 'roaming.waybill.datatable.row.total'),
            cellProps: {
                className: 'text-end'
            },
            headerProps: {width: 300, className: 'ps-3 text-end'},
            Cell: ({row: {original}}) => {
                const total = original.total ? original?.total : original.items.reduce((acc, item) => acc + item.total, 0);
                return (
                    <Fragment>
                        <strong>{numeral.formats[numberFormat].format(total)}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </Fragment>
                )
            }
        },

        {
            accessor: 'status',
            Header: t(lang, 'roaming.waybill.datatable.row.status'),
            cellProps: {className: "text-end"},
            headerProps: {className: "text-end"},
            Cell: ({row: {original}}) => {
                return (
                    <div className={'d-inline-block text-center'}>
                        <Badge
                            variant={"phoenix"}
                            bg={'warning-davr'}
                            className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >
                            {t(lang, getWaybillStatusText(original, viewer_inn_or_pinfl))}
                        </Badge>
                    </div>
                )
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    if (viewer === VIEW_DRAFT) {
                                        navigate('/roaming/waybill/draft/' + original.id)
                                    } else {
                                        navigate('/roaming/waybill/' + original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ]


    return (
        <DashboardDatatable
            columns={columns}
            data={waybills}
            setViewer={setViewer}
            documentName={'edi.navbar.routes.edo.waybill'}
        />
    );
};

export default DashboardWaybillDatatable;
