import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../lang/lang";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../store/reducers/main/main";
import {
    getWaybillV2CustomerCount,
    getWaybillV2DraftsCount,
    getWaybillV2ExecutorCount, getWaybillV2ResponsiblePersonCount,
    loadWaybillV2CustomerAsync,
    loadWaybillV2DraftsAsync,
    loadWaybillV2ExecutorAsync,
    loadWaybillV2ResponsiblePersonAsync,
    selectWaybillsV2,
    selectWaybillsV2Count,
    selectWaybillsV2FilterOptions,
    selectWaybillsV2Loading,
    viewPage
} from "../../store/reducers/roaming/waybill-v2/romaingWaybillV2Reducer";
import {selectActiveOrganization} from "../../store/reducers/auth/authReducer";
import {
    getWaybillStatusText,
    getWaybillStatusVariant,
    RESPONSIBLE_PERSON,
    VIEW_DRAFTS,
    VIEW_INBOX,
    VIEW_OUTBOX
} from "../../enum/roaming/waybill/WaybillStatus";
import IconTransaction from "../../assets/icon/IconTransaction";
import dayjs from "dayjs";
import numeral from "numeral";
import {VIEW_DRAFT} from "../../enum/roaming/TypeViewers";
import Badge from "../../components/common/Badge";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {Dropdown} from "react-bootstrap";
import DashboardDatatable from "./DashboardDatatable";
import {checkViewer} from "../../enum/roaming/checkViewer";
import {getWaybillV2Status} from "../../enum/roaming/waybill-v2/WaybillV2Status";
import WaybillV2DraftDelete from "../../components/roaming/waybill-v2/WaybillV2DraftDelete";

const DashboardWaybillV2Datatable = ({setWaybillV2Count}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const t = Translate;
    const lang = useSelector(selectLang);
    const waybills = useSelector(selectWaybillsV2);
    const count = useSelector(selectWaybillsV2Count);
    const loading = useSelector(selectWaybillsV2Loading);
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const numberFormat = useSelector(selectNumberFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const viewer_inn_or_pinfl = activeOrganization?.pinfl || activeOrganization?.inn;
    const [viewer, setViewer] = useState("inbox")

    const loadWaybills = () => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadWaybillV2CustomerAsync({status: 120, ...filter}));
            dispatch(getWaybillV2CustomerCount({status: 120, ...filter}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadWaybillV2ExecutorAsync({status: 120, ...filter}));
            dispatch(getWaybillV2ExecutorCount({status: 120, ...filter}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillV2DraftsAsync({status: 120, ...filter}));
            dispatch(getWaybillV2DraftsCount({status: 120, ...filter}));
        } else if (viewer === RESPONSIBLE_PERSON) {
            dispatch(loadWaybillV2ResponsiblePersonAsync({status: 120, ...filter}));
            dispatch(getWaybillV2ResponsiblePersonCount({status: 120, ...filter}));
        }
    }


    useEffect(() => {
        setWaybillV2Count(count)
        loadWaybills()
    }, [count, viewer]);

    const columns = [
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.waybill.datatable.row.contractor'),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: "ps-3"},
            Cell: ({row: {original}}) => {
                if (viewer_inn_or_pinfl === original.executor.inn || viewer_inn_or_pinfl === original.executor.inn_or_pinfl)
                    return <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.customer?.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.customer?.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.customer?.inn_or_pinfl || original.customer?.inn}</i>
                        </div>
                    </div>
                else
                    return <div className={'d-flex align-items-center gap-2'}>
                        <IconTransaction/>
                        <div>
                             <span className={'d-block fs-9 second-text'}>
                                    № {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                                 name: original?.info?.number,
                                 date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                             })}
                             </span>
                            <div style={{
                                maxWidth: '300px',
                                minWidth: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                 title={original.executor.name}
                                 className={'fs-8 main-text fw-bold text-truncate'}>{original.executor.name}</div>
                            <i className={'d-block fs-9 second-text'}>{original.executor?.inn || original.executor?.inn_or_pinfl}</i>
                        </div>
                    </div>
            }
        },
        {
            accessor: 'total',
            Header: t(lang, 'roaming.waybill.datatable.row.total'),
            cellProps: {
                className: 'text-end'
            },
            headerProps: {width: 300, className: 'ps-3 text-end'},
            Cell: ({row: {original}}) => {
                const total = original.total ? original?.total : original.roadway?.product_groups[0]?.products?.reduce((acc, item) => acc + item.total, 0);
                return (
                    <Fragment>
                        <strong>{total ? numeral?.formats[numberFormat]?.format(total) : 0}</strong>
                        <span className={'ms-1 second-text fs-9'}>UZS</span>
                    </Fragment>
                )
            }
        },

        {
            accessor: 'status',
            Header: t(lang, 'roaming.waybill.datatable.row.status'),
            cellProps: {className: "text-end"},
            headerProps: {className: "text-end"},
            Cell: ({row: {original}}) => {
                return (
                    <div className={'d-inline-block text-center'}>
                        <Badge
                            variant={"phoenix"}
                            bg={'danger-davr'}
                            className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >
                            {t(lang, getWaybillV2Status(original.status).label)}
                        </Badge>
                    </div>
                )
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={(event) => {
                                    if (viewer === VIEW_DRAFT) {
                                        navigate('/roaming/waybill-v2/draft/' + original.id)
                                    } else {
                                        navigate('/roaming/waybill-v2/' + original.id)
                                    }
                                }}>
                                <span>{t(lang, "roaming.invoices.table_header.show")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ]


    return (
        <DashboardDatatable
            columns={columns}
            data={waybills}
            setViewer={setViewer}
            documentName={'edi.navbar.routes.edo.waybill.v2'}
        />
    );
};

export default DashboardWaybillV2Datatable;
