import React, {Fragment} from 'react';
import AdvanceTableWrapper from "../../components/table/AdvanceTableWrapper";
import {Card, Dropdown} from "react-bootstrap";
import Flex from "../../components/common/Flex";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {GetViewerText, VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../enum/roaming/TypeViewers";
import AdvanceTable from "../../components/table/AdvanceTable";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";

const DashboardDatatable = ({setViewer, columns, data = [], draft, documentName = ''}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={data}
                perPage={3}
                pagination
            >
                <Card className={'p-0 rounded-4 mt-3'}>
                    <Flex className={'p-2 align-items-center gap-2'}>
                        <div className={'d-flex gap-2 align-items-center'}>
                            <h4>{t(lang, "roaming.dashboard.status.waiting")}</h4>
                            <h4>({t(lang, documentName)})</h4>
                        </div>
                        <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown icon={<FontAwesomeIcon icon={faChevronRight} size={"sm"} className={'text-primary-davr'} />}>
                                <Dropdown.Item
                                    onClick={() => {
                                        setViewer("inbox")
                                    }}
                                    className="text-primary">
                                    <span className={'fw-medium'}>{t(lang, GetViewerText(VIEW_INBOX))}</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        setViewer("outbox")
                                    }}
                                    className="text-primary">
                                    <span className={'fw-medium'}>{t(lang, GetViewerText(VIEW_OUTBOX))}</span>
                                </Dropdown.Item>
                               {/* {!draft && <Dropdown.Item
                                    onClick={() => {
                                        setViewer("draft")
                                    }}
                                    className="text-primary">
                                    <span className={'fw-medium'}>{t(lang, GetViewerText(VIEW_DRAFT))}</span>
                                </Dropdown.Item>}*/}
                            </RevealDropdown>
                        </RevealDropdownTrigger>
                    </Flex>
                    <AdvanceTable
                        table
                        headerClassName="text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        // isLoading={isLoading}
                        tableProps={{
                            size: 'sm',
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default DashboardDatatable;
