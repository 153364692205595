export const ADD_ORDER_REQUESTED = 'ADD_ORDER_REQUESTED';
export const ADD_ORDER_FAILED = 'ADD_ORDER_FAILED';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const EXECUTOR_DECLINE_ORDER_REQUESTED = 'EXECUTOR_DECLINE_ORDER_REQUESTED';
export const EXECUTOR_DECLINE_ORDER_SUCCESS = 'EXECUTOR_DECLINE_ORDER_SUCCESS';
export const EXECUTOR_DECLINE_ORDER_FAILED = 'EXECUTOR_DECLINE_ORDER_FAILED';
export const EXECUTOR_APPROVED_ORDER = 'EXECUTOR_APPROVED_ORDER';
export const CUSTOMER_APPROVED_ORDER = 'CUSTOMER_APPROVED_ORDER';
export const EXECUTOR_CANCEL_DECLINE_REQUESTED = "EXECUTOR_CANCEL_DECLINE_REQUESTED";
export const EXECUTOR_CANCEL_DECLINE_SUCCESS = "EXECUTOR_CANCEL_DECLINE_SUCCESS";
export const EXECUTOR_CANCEL_DECLINE_FAILED = "EXECUTOR_CANCEL_DECLINE_FAILED";
export const STOP_ORDER_REQUESTED = 'STOP_ORDER_REQUESTED';
export const STOP_ORDER_SUCCESS = 'STOP_ORDER_SUCCESS';
export const STOP_ORDER_FAILED = 'STOP_ORDER_FAILED';
export const RESUME_ORDER_REQUESTED = 'RESUME_ORDER_REQUESTED';
export const RESUME_ORDER_SUCCESS = 'RESUME_ORDER_SUCCESS';
export const RESUME_ORDER_FAILED = 'RESUME_ORDER_FAILED';

export const IDENTIFY_ORDER_ITEMS_REQUESTED = 'IDENTIFY_ORDER_ITEMS_REQUESTED';
export const IDENTIFY_ORDER_ITEMS_SUCCESS = 'IDENTIFY_ORDER_ITEMS_SUCCESS';
export const IDENTIFY_ORDER_ITEMS_FAILED = 'IDENTIFY_ORDER_ITEMS_FAILED';
