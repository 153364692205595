import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Flex from "../../components/common/Flex";
import IconFileDownload from "../../assets/icon/IconFileDownload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconFileUpload from "../../assets/icon/IconFileUpload";
import IconFileDocument from "../../assets/icon/IconFileDocument";
import IconFileEdit from "../../assets/icon/IconFileEdit";
import DashboardInvoiceDatatable from "./DashboardInvoiceDatatable";
import DashboardContractDatatable from "./DashboardContractDatatable";
import DashboardActDatatable from "./DashboardActDatatable";
import DashboardVerificationAct from "./DashboardVerificationAct";
import DashboardEmpowermentsDatatable from "./DashboardEmpowermentsDatatable";
import DashboardFreeFormDatatable from "./DashboardFreeFormDatatable";
import DashboardWaybillDatatable from "./DashboardWaybillDatatable";
import DashboardDropdownButton from "../../components/common/DashboardDropdownButton";
import {scrollEvent} from "../../helpers/scrollEvent";
import DashboardWaybillV2Datatable from "./DashboardWaybillV2Datatable";

const RoamingDashboard = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [invoiceCount, setInvoiceCount] = useState(0)
    const [contractCount, setContractCount] = useState(0)
    const [actCount, setActCount] = useState(0)
    const [verificationAct, setVerificationAct] = useState(0)
    const [empowermentsCount, setEmpowementsCount] = useState(0)
    const [freeFormCount, setFreeFormCount] = useState(0)
    const [waybillCount, setWaybillCount] = useState(0)
    const [waybillV2Count, setWaybillV2Count] = useState(0)

    useEffect(() => {
        window.addEventListener('scroll',() => scrollEvent('roaming-dashboard'));
        return () => window.removeEventListener('scroll', () => scrollEvent('roaming-dashboard'))
    }, []);

    return (
        <Fragment>
            <Flex id={'roaming-dashboard'} className={'align-items-center justify-content-between position-sticky z-index-1'} style={{top: '4rem'}}>
                <h4>{t(lang, "Докоборот")}</h4>
                <DashboardDropdownButton />
            </Flex>
            <Row className={'mt-3'}>
                <Col xs={12} md={6} lg={3}>
                    <Card as={Link} to={'/roaming/invoices/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileUpload />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.invoice")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{invoiceCount}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Card as={Link} to={'/roaming/contracts/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileDownload />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.contract")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{contractCount}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Card as={Link} to={'/roaming/acts/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileDocument />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.act")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{actCount}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Card as={Link} to={'/roaming/verification-acts/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileDocument />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.verification_act")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{verificationAct}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className={'mt-3'}>
                    <Card as={Link} to={'/roaming/empowerments/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileDocument />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.empowerment")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{empowermentsCount}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className={'mt-3'}>
                    <Card as={Link} to={'/roaming/free-forms/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileEdit />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.free_form_document")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{freeFormCount}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className={'mt-3'}>
                    <Card as={Link} to={'/roaming/waybills/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileEdit />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.waybill")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{waybillCount}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3} className={'mt-3'}>
                    <Card as={Link} to={'/roaming/v2-waybills/inbox'} className={'p-2 text-decoration-none'}>
                        <Flex className={'gap-2'}>
                            <IconFileEdit />
                            <span className={'second-text'}>{t(lang, "edi.navbar.routes.edo.waybill.v2")}</span>
                        </Flex>
                        <Flex className={'justify-content-between'}>
                            <h2 className={'fw-medium'}>{waybillV2Count}</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
            </Row>

            <DashboardInvoiceDatatable setInvoiceCount={setInvoiceCount} />
            <DashboardContractDatatable setContractCount={setContractCount} />
            <DashboardActDatatable setActCount={setActCount} />
            <DashboardVerificationAct setVerificationAct={setVerificationAct} />
            <DashboardEmpowermentsDatatable setEmpowermentCount={setEmpowementsCount} />
            <DashboardFreeFormDatatable setFreeFormCount={setFreeFormCount} />
            <DashboardWaybillDatatable setWaybillCount={setWaybillCount} />
            <DashboardWaybillV2Datatable setWaybillV2Count={setWaybillV2Count} />
        </Fragment>
    );
};

export default RoamingDashboard;
