import React, {Fragment} from 'react';
import AccountSettings from "../../components/settings/system/AccountSettings";
import {Card, Col, Row} from "react-bootstrap";
import ChangePassword from "../../components/settings/system/ChangePassword";
import ChangeLanguage from "../../components/settings/system/ChangeLanguage";

const Systems = () => {
    return (
        <Fragment>
            <Row className={'justify-content-center'}>
               <Col xs={12} md={4}>
                   <Card className={'p-1 h-100'}>
                       <AccountSettings />
                   </Card>
               </Col>
                <Col xs={12} md={4}>
                    <Card className={'p-1 h-100'}>
                        <ChangeLanguage />
                    </Card>
               </Col>
                {/*<Col xs={12} md={4}>
                    <Card className={'p-1 h-100'}>
                        <ChangePassword />
                    </Card>
               </Col>*/}
            </Row>
        </Fragment>
    );
};

export default Systems;
