export const SAVE_DRAFT_WAYBILL_v2_REQUESTED = 'SAVE_DRAFT_WAYBILL_v2_REQUESTED';
export const SAVE_DRAFT_WAYBILL_v2_SUCCEED = 'SAVE_DRAFT_WAYBILL_v2_SUCCEED';
export const SAVE_DRAFT_WAYBILL_v2_FAILED = 'SAVE_DRAFT_WAYBILL_v2_FAILED';

export const SEND_WAYBILL_V2_REQUESTED = 'SEND_WAYBILL_V2_REQUESTED';
export const SEND_WAYBILL_V2_SUCCEED = 'SEND_WAYBILL_V2_SUCCEED';
export const SEND_WAYBILL_V2_FAILED = 'SEND_WAYBILL_V2_FAILED';

export const DELETE_WAYBILL_V2_REQUESTED = 'DELETE_WAYBILL_V2_REQUESTED';
export const DELETE_WAYBILL_V2_SUCCEED = 'DELETE_WAYBILL_V2_SUCCEED';
export const DELETE_WAYBILL_V2_FAILED = 'DELETE_WAYBILL_V2_FAILED';

export const WAYBILL_V2_BULK_ACTION_STARTED = 'WAYBILL_V2_BULK_ACTION_STARTED';
export const WAYBILL_V2_BULK_ACTION_FINISHED = 'WAYBILL_V2_BULK_ACTION_FINISHED';

export const CONSIGNOR_CANCEL_REQUESTED = 'CONSIGNOR_CANCEL_REQUESTED';
export const CONSIGNOR_CANCEL_SUCCEED = 'CONSIGNOR_CANCEL_SUCCEED';
export const CONSIGNOR_CANCEL_FAILED = 'CONSIGNOR_CANCEL_FAILED';

export const RETURN_ACCEPT_SUCCEED = 'RETURN_ACCEPT_SUCCEED';
export const RETURN_ACCEPT_FAILED = 'RETURN_ACCEPT_FAILED';

export const CUSTOMER_ACCEPT_SUCCEED = 'CUSTOMER_ACCEPT_SUCCEED';
export const CUSTOMER_ACCEPT_FAILED = 'CUSTOMER_ACCEPT_FAILED';

export const CUSTOMER_DECLINE_SUCCEED = 'CUSTOMER_DECLINE_SUCCEED';
export const CUSTOMER_DECLINE_FAILED = 'CUSTOMER_DECLINE_FAILED';

export const RESPONSIBLE_PERSON_REJECT_SUCCEED = 'RESPONSIBLE_PERSON_REJECT_SUCCEED';
export const RESPONSIBLE_PERSON_REJECT_FAILED = 'RESPONSIBLE_PERSON_REJECT_FAILED';

export const RESPONSIBLE_PERSON_ACCEPT_SUCCEED = 'RESPONSIBLE_PERSON_ACCEPT_SUCCEED';
export const RESPONSIBLE_PERSON_ACCEPT_FAILED = 'RESPONSIBLE_PERSON_ACCEPT_FAILED';

export const RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED = 'RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED';
export const RESPONSIBLE_PERSON_TILL_RETURN_FAILED = 'RESPONSIBLE_PERSON_TILL_RETURN_FAILED';

export const RESPONSIBLE_PERSON_GIVE_SUCCEED = 'RESPONSIBLE_PERSON_GIVE_SUCCEED';
export const RESPONSIBLE_PERSON_GIVE_FAILED = 'RESPONSIBLE_PERSON_GIVE_FAILED';

export const RESPONSIBLE_PERSON_RETURN_SUCCEED = 'RESPONSIBLE_PERSON_RETURN_SUCCEED';
export const RESPONSIBLE_PERSON_RETURN_FAILED = 'RESPONSIBLE_PERSON_RETURN_FAILED';


export const WAYBILL_V2_SYNC_REQUESTED = 'WAYBILL_V2_SYNC_REQUESTED';
export const WAYBILL_V2_SYNC_SUCCESS = 'WAYBILL_V2_SYNC_SUCCESS';
export const WAYBILL_V2_SYNC_FAILED = 'WAYBILL_V2_SYNC_FAILED';

export const HASHCODE_CANCEL_REQUESTED = 'HASHCODE_CANCEL_REQUESTED';
export const HASHCODE_CANCEL_SUCCESS = 'HASHCODE_CANCEL_SUCCESS';
export const HASHCODE_CANCEL_FAILED = 'HASHCODE_CANCEL_FAILED';
