import ReasonType from "./ReasonType";

export const LackOfGoods = ReasonType(1, 'edi.shipment.delete_reason_type.lack_of_goods');
export const ChangePriceOfGoods = ReasonType(2, 'edi.shipment.delete_reason_type.change_price_of_goods');
export const ChangeDateOfContraction = ReasonType(3, 'edi.shipment.delete_reason_type.change_date_of_contracts');
export const HasDebtFromLastGoods = ReasonType(4, 'edi.shipment.delete_reason_type.has_debt_from_last_goods');
export const LackOfTransportationForGoods = ReasonType(5, 'edi.shipment.delete_reason_type.lack_of_transportation_for_goods');
export const HasForceMajorConditions = ReasonType(6, 'edi.shipment.delete_reason_type.has_force_major_conditions');


export const ShipmentDeleteStatuses = [
    LackOfGoods,
    ChangePriceOfGoods,
    ChangeDateOfContraction,
    HasDebtFromLastGoods,
    LackOfTransportationForGoods,
    HasForceMajorConditions
]

export const getShipmentDeleteReasonText = reason => {
    switch (reason) {
        case LackOfGoods.id:
            return LackOfGoods.name;
        case ChangePriceOfGoods.id:
            return ChangePriceOfGoods.name;
        case ChangeDateOfContraction.id:
            return ChangeDateOfContraction.name;
        case HasDebtFromLastGoods.id:
            return HasDebtFromLastGoods.name;
        case LackOfTransportationForGoods.id:
            return LackOfTransportationForGoods.name;
        case HasForceMajorConditions.id:
            return HasForceMajorConditions.name;
        default:
            return ''
    }
}
