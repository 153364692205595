import {createSlice} from "@reduxjs/toolkit";
import {attachPkcs7WithTimestamp, attachTimestampAsync, createPkcs7WithTimestamp} from "../../eimzo/eimzoReducer";
import {
    CUSTOMER_CANCEL_EMPOWERMENT_FAILED,
    CUSTOMER_CANCEL_EMPOWERMENT_REQUESTED,
    CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS,
    EMPOWERMENT_DRAFT_FAILED,
    EMPOWERMENT_DRAFT_REQUESTED,
    EMPOWERMENT_DRAFT_SUCCESS,
    EMPOWERMENT_SEND_FAILED,
    EMPOWERMENT_SEND_REQUESTED,
    EMPOWERMENT_SEND_SUCCESS,
    EXECUTOR_ACCEPT_EMPOWERMENT_FAILED,
    EXECUTOR_ACCEPT_EMPOWERMENT_REQUESTED,
    EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS,
    EXECUTOR_DECLINE_EMPOWERMENT_FAILED,
    EXECUTOR_DECLINE_EMPOWERMENT_REQUESTED,
    EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS,
    PERSON_ACCEPT_EMPOWERMENT_FAILED,
    PERSON_ACCEPT_EMPOWERMENT_REQUESTED,
    PERSON_ACCEPT_EMPOWERMENT_SUCCESS,
    PERSON_DECLINE_EMPOWERMENT_FAILED,
    PERSON_DECLINE_EMPOWERMENT_REQUESTED,
    PERSON_DECLINE_EMPOWERMENT_SUCCESS,
    SYNC_EMPOWERMENT_FAILED,
    SYNC_EMPOWERMENT_REQUESTED,
    SYNC_EMPOWERMENT_SUCCESS
} from "../../../../eventbus/romaing/empowerment/empowermentEvents";
import {VIEWER_CUSTOMER, VIEWER_DRAFT, VIEWER_EXECUTOR, VIEWER_PERSON} from "../../../../enum/roaming/empowerment/EmpowermentStatus";
import {Translate} from "../../../../lang/lang";
import EventBus from "../../../../eventbus/EventBus";
import RoamingService from "../../../services/roaming/roamingService";
import empowermentService from "../../../services/empowerment/empowermentService";


const t = Translate;

export const roamingEmpowermentSlice = createSlice({
    name: 'roamingEmpowerment',
    initialState: {
        empowerments: [],
        filterOptions: {page: 1, limit: 10},
        countEmpowerments: 0,
        isLoading: false,
        viewPage: {
            id: null,
            draftId: null,
            showModal: false
        }
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, draftId: null, showModal: false}
            }else {
                state.viewPage.showModal = action.payload
            }
        },
        setId: (state, {payload}) => {
            const {draftId, id} = payload
            if (draftId) {
                state.viewPage.draftId = draftId
            } else if (id) {
                state.viewPage.id = id
            }
        },
        updateEmpowerments: (state, action) => {
            state.empowerments = action.payload
        },
        updateCountEmpowerments: (state, action) => {
            state.countEmpowerments = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
})

export const sendEmpowermentAsync = async (certificate, lang, hashcode) => {
    EventBus.dispatch(EMPOWERMENT_SEND_REQUESTED, hashcode);
    let errorResponse;
    await createPkcs7WithTimestamp(hashcode, certificate.keyId)
        .then(async ({pkcs7: signature, signatureHex, signerSerialNumber}) => {
            await RoamingService.getTimestamp(signatureHex)
                .then(async ({data: timeStamp}) => {
                    await attachTimestampAsync(signature, signerSerialNumber, timeStamp)
                        .then(async ({pkcs7: roamingSignature}) => {
                            await empowermentService.sendEmpowerment({signature: roamingSignature, lang})
                                .then((response) => {
                                    EventBus.dispatch(EMPOWERMENT_SEND_SUCCESS, response.data);
                                    return response.data;
                                })
                                .catch(error => {
                                    errorResponse = errorSender(error.response.data, lang)
                                    EventBus.dispatch(EMPOWERMENT_SEND_FAILED, errorResponse);
                                })
                        })
                        .catch(error => {
                            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachTimestampAsync")}
                            EventBus.dispatch(EMPOWERMENT_SEND_FAILED, errorResponse);
                        })
                })
                .catch(error => {
                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getTimestamp")};
                    EventBus.dispatch(EMPOWERMENT_SEND_FAILED, errorResponse);
                })
        })
        .catch(error => {
            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7WithTimestamp")};
            EventBus.dispatch(EMPOWERMENT_SEND_FAILED, errorResponse);
        })
};

export const draftEmpowermentAsync = async (empowermentDraftSend) => {
    EventBus.dispatch(EMPOWERMENT_DRAFT_REQUESTED)

    return new Promise((resolve, reject) => {
        empowermentService.draftEmpowerment(empowermentDraftSend)
            .then(response => {
                EventBus.dispatch(EMPOWERMENT_DRAFT_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch(error => {
                EventBus.dispatch(EMPOWERMENT_DRAFT_FAILED)
                reject(error)
            })
    })
}


export const customerCancelEmpowermentAsync = async (certificate, id, lang) => {
    EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_REQUESTED, id);
    let errorResponse;
    await empowermentService.getCancelHashCode(id)
        .then(async ({data: hashCode}) => {
            await createPkcs7WithTimestamp(hashCode, certificate.keyId)
                .then(async ({pkcs7: signature, signatureHex, signerSerialNumber}) => {
                    await RoamingService.getTimestamp(signatureHex)
                        .then(async ({data: timeStamp}) => {
                            await attachTimestampAsync(signature, signerSerialNumber, timeStamp)
                                .then(async ({pkcs7: roamingSignature}) => {
                                    await empowermentService.customerCancelEmpowerment({id, signature: roamingSignature})
                                        .then((response) => {
                                            EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, response.data)
                                            return response.data;
                                        })
                                        .catch(error => {
                                            errorResponse = errorSender(error.response.data, lang)
                                            EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, errorResponse);
                                        })
                                })
                                .catch(error => {
                                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachTimestampAsync")}
                                    EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, errorResponse);
                                })
                        })
                        .catch(error => {
                            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getTimestamp")}
                            EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, errorResponse);
                        })
                })
                .catch(error => {
                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7WithTimestamp")}
                    EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, errorResponse);
                })
        })
        .catch(error => {
            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getCancelHashCode")}
            EventBus.dispatch(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, errorResponse);
        })
};
export const personAcceptEmpowermentAsync = async (certificate, id, lang) => {
    EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_REQUESTED, id);
    let errorResponse;
    await empowermentService.getAcceptHashCode(id)
        .then(async ({data: hashCode}) => {
            await attachPkcs7WithTimestamp(hashCode, certificate.keyId)
                .then(async ({pkcs7: roamingPkcs7, signerSerialNumber, signatureHex}) => {
                    await RoamingService.getTimestamp(signatureHex)
                        .then(async ({data: timeStamp}) => {
                            await attachTimestampAsync(roamingPkcs7, signerSerialNumber, timeStamp)
                                .then(async ({pkcs7: roamingSignature}) => {
                                    await empowermentService.personAcceptEmpowerment({id, signature: roamingSignature})
                                        .then((response) => {
                                            EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, response.data)
                                            return response.data;
                                        })
                                        .catch(error => {
                                            errorResponse = errorSender(error.response.data, lang);
                                            EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                                        })
                                })
                                .catch(error => {
                                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachTimestampAsync")};
                                    EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                                })
                        })
                        .catch(error => {
                            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getTimestamp")};
                            EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                        })
                })
                .catch(error => {
                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachPkcs7WithTimestamp")};
                    EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                })
        })
        .catch(error => {
            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getAcceptHashCode")};
            EventBus.dispatch(PERSON_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
        })
};
export const personDeclineEmpowermentAsync = async (certificate, payload, lang) => {
    EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_REQUESTED, payload);
    let errorResponse;
    const {id, comment} = payload;
    await empowermentService.getDeclineHashCode(id, comment)
        .then(async ({data: hashCode}) => {
            await createPkcs7WithTimestamp(hashCode, certificate.keyId)
                .then(async ({pkcs7: signature, signatureHex, signerSerialNumber}) => {
                    await RoamingService.getTimestamp(signatureHex)
                        .then(async ({data: timeStamp}) => {
                            await attachTimestampAsync(signature, signerSerialNumber, timeStamp)
                                .then(async ({pkcs7: roamingSignature}) => {
                                    await empowermentService.personDeclineEmpowerment({id, signature: roamingSignature, comment})
                                        .then((response) => {
                                            EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_SUCCESS, response.data)
                                            return response.data;
                                        })
                                        .catch(error => {
                                            errorResponse = errorSender(error.response.data, lang)
                                            EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                                        })
                                })
                                .catch(error => {
                                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachTimestampAsync")}
                                    EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                                })
                        })
                        .catch(error => {
                            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getTimestamp")}
                            EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                        })
                })
                .catch(error => {
                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7WithTimestamp")}
                    EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                })
        })
        .catch(error => {
            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getDeclineHashCode")}
            EventBus.dispatch(PERSON_DECLINE_EMPOWERMENT_FAILED, errorResponse);
        })
};
export const executorAcceptEmpowermentAsync = async (certificate, id, lang) => {
    EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_REQUESTED, {id});
    let errorResponse;
    await empowermentService.getAcceptHashCode(id)
        .then(async ({data: hashCode}) => {
            await attachPkcs7WithTimestamp(hashCode, certificate.keyId)
                .then(async ({pkcs7: roamingPkcs7, signerSerialNumber, signatureHex}) => {
                    await RoamingService.getTimestamp(signatureHex)
                        .then(async ({data: timeStamp}) => {
                            await attachTimestampAsync(roamingPkcs7, signerSerialNumber, timeStamp)
                                .then(async ({pkcs7: roamingSignature}) => {
                                    await empowermentService.executorAcceptEmpowerment({id, signature: roamingSignature})
                                        .then((response) => {
                                            EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, response.data)
                                            return response.data;
                                        })
                                        .catch(error => {
                                            errorResponse = errorSender(error.response.data, lang);
                                            EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                                        })
                                })
                                .catch(error => {
                                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachTimestampAsync")};
                                    EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                                })
                        })
                        .catch(error => {
                            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getTimestamp")};
                            EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                        })
                })
                .catch(error => {
                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachPkcs7WithTimestamp")};
                    EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
                })
        })
        .catch(error => {
            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getAcceptHashCode")};
            EventBus.dispatch(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, errorResponse);
        })
};
export const executorDeclineEmpowermentAsync = async (certificate, payload, lang) => {
    EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_REQUESTED, payload);
    let errorResponse;
    const {id, comment} = payload;
    await empowermentService.getDeclineHashCode(id, comment)
        .then(async ({data: hashCode}) => {
            await createPkcs7WithTimestamp(hashCode, certificate.keyId)
                .then(async ({pkcs7: signature, signatureHex, signerSerialNumber}) => {
                    await RoamingService.getTimestamp(signatureHex)
                        .then(async ({data: timeStamp}) => {
                            await attachTimestampAsync(signature, signerSerialNumber, timeStamp)
                                .then(async ({pkcs7: roamingSignature}) => {
                                    await empowermentService.executorDeclineEmpowerment({id, signature: roamingSignature, comment
                                    })
                                        .then(response => {
                                            EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, response.data)
                                            return response.data;
                                        })
                                        .catch(error => {
                                            errorResponse = errorSender(error.response.data, lang)
                                            EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                                        })
                                })
                                .catch(error => {
                                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.attachTimestampAsync")}
                                    EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                                })
                        })
                        .catch(error => {
                            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getTimestamp")}
                            EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                        })
                })
                .catch(error => {
                    errorResponse = {message: t(lang, "roaming.empowerment.alert.error.createPkcs7WithTimestamp")}
                    EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, errorResponse);
                })
        })
        .catch(error => {
            errorResponse = {message: t(lang, "roaming.empowerment.alert.error.getDeclineHashCode")}
            EventBus.dispatch(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, errorResponse);
        })

};
export const syncEmpowermentAsync = (id) => {
    EventBus.dispatch(SYNC_EMPOWERMENT_REQUESTED, id);
    return new Promise((resolve, reject) => {
        empowermentService.syncEmpowerment(id)
            .then(response => {
                EventBus.dispatch(SYNC_EMPOWERMENT_SUCCESS);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(SYNC_EMPOWERMENT_FAILED);
                reject(error);
            })
    })

};

export const loadEmpowermentAsync = async (id) => {
    const {data} = await empowermentService.getEmpowerment(id);
    return data;
};

export const loadDraftEmpowerment = async (id) => {
    return new Promise((resolve, reject) => {
        empowermentService.getEmpowermentDraft(id)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const loadEmpowermentsAsync = (viewer, params) => (dispatch) => {
    switch (viewer) {
        case VIEWER_CUSTOMER:
            dispatch(loadCustomerEmpowermentsAsync(params));
            dispatch(loadCustomerCountEmpowermentsAsync(params));
            break;
        case VIEWER_EXECUTOR:
            dispatch(loadExecutorEmpowermentsAsync(params));
            dispatch(loadExecutorCountEmpowermentsAsync(params));
            break;
        case VIEWER_PERSON:
            dispatch(loadPersonEmpowermentsAsync(params));
            dispatch(loadPersonCountEmpowermentsAsync(params));
            break;
        case VIEWER_DRAFT:
            dispatch(loadDraftEmpowermentsAsync(params))
            dispatch(loadDraftCountEmpowermentsAsync(params))
            break;
    }
};

export const loadCustomerEmpowermentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    dispatch(roamingEmpowermentSlice.actions.setLoading(true));
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    empowermentService.getCustomerEmpowerments(params)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateEmpowerments(response.data));
            dispatch(roamingEmpowermentSlice.actions.setLoading(false));
        })
        .catch(error => {
            console.log(error);
            dispatch(roamingEmpowermentSlice.actions.setLoading(false));
        })
};
export const loadPersonEmpowermentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    dispatch(roamingEmpowermentSlice.actions.setLoading(true));
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    empowermentService.getPersonEmpowerments(params)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateEmpowerments(response.data));
            dispatch(roamingEmpowermentSlice.actions.setLoading(false));
        })
        .catch(error => {
            console.log(error);
            dispatch(roamingEmpowermentSlice.actions.setLoading(false));
        })
};
export const loadExecutorEmpowermentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    dispatch(roamingEmpowermentSlice.actions.setLoading(true));
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    empowermentService.getExecutorEmpowerments(params)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateEmpowerments(response.data));
            dispatch(roamingEmpowermentSlice.actions.setLoading(false));
        })
        .catch(error => {
            console.log(error);
            dispatch(roamingEmpowermentSlice.actions.setLoading(false));
        })
};
export const loadDraftEmpowermentsAsync = ({page,limit, ...filters}) => (dispatch) => {
    dispatch(roamingEmpowermentSlice.actions.setLoading(true));
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    empowermentService.getExecutorDraftEmpowerments(params)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateEmpowerments(response.data));
            dispatch(roamingEmpowermentSlice.actions.setLoading(false))
        })
        .catch(error => {
            console.log(error)
            dispatch(roamingEmpowermentSlice.actions.setLoading(false))
        })
}

export const loadCustomerCountEmpowermentsAsync = ({limit, page, ...other}) => (dispatch) => {
    empowermentService.getCustomerCountEmpowerments(other)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateCountEmpowerments(response.data))
        })
        .catch(error => {
            console.log(error);
        })
};
export const loadPersonCountEmpowermentsAsync = ({limit, page, ...other}) => (dispatch) => {
    empowermentService.getExecutorCountEmpowerments(other)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateCountEmpowerments(response.data))
        })
        .catch(error => {
            console.log(error);
        })
};
export const loadExecutorCountEmpowermentsAsync = ({limit, page, ...other}) => (dispatch) => {
    empowermentService.getExecutorCountEmpowerments(other)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateCountEmpowerments(response.data))
        })
        .catch(error => {
            console.log(error);
        })
};
export const loadDraftCountEmpowermentsAsync = ({limit, page, ...other}) => (dispatch) => {
    empowermentService.getExecutorCountDraftEmpowerments(other)
        .then(response => {
            dispatch(roamingEmpowermentSlice.actions.updateCountEmpowerments(response.data))
        })
        .catch(error => {
            console.log(error)
        })
}

export const changeFilterOptionsAsync = params => dispatch => {
    return dispatch(roamingEmpowermentSlice.actions.updateFilterOptions(params))
};

export const printEmpowermentAsync = (id) => {
    return new Promise((resolve, reject) => {
        empowermentService.printEmpowerment(id)
            .then(response => resolve(response.data))
            .catch(error => {
                console.log(error);
                reject(error)
            })
    })
}
const errorSender = (errorResponse, lang) => {
    let detectedError;
    if (errorResponse.content)
        detectedError = {message: JSON.parse(errorResponse.content).ErrorMessage}
    else if (errorResponse.not_allowed_inn)
        detectedError = {message: t(lang, 'roaming.empowerment.alert.not_allowed_inn')}
    else if (errorResponse.content_parse_failed)
        detectedError = {message: t(lang, 'roaming.empowerment.alert.content_parse_failed')}
    else
        detectedError = {message: t(lang, 'roaming.empowerment.alert.default_error')}
    return detectedError;
};

export const viewPage = state => state.roamingEmpowerment.viewPage
export const selectIsLoading = state => state.roamingEmpowerment.isLoading;
export const selectEmpowerments = state => state.roamingEmpowerment.empowerments;
export const selectCountEmpowerments = (state) => state.roamingEmpowerment.countEmpowerments;
export const selectFilterOptions = (state) => state.roamingEmpowerment.filterOptions;


export default roamingEmpowermentSlice.reducer;
export const {setShowModal, setId} = roamingEmpowermentSlice.actions
